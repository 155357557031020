<template>
    <div>
        <b-row no-gutters>
            <b-col>
                <b-col class="favorite_header">
                    <h1>Избранное</h1>
                </b-col>
                <div v-if="$user">
                    <div v-if="preLoad">
                        <div v-if="list.length">
                        <b-card no-body class="favorite_card overflow-hidden" v-for="item in org" :key="item.id">
                            <router-link :to="{name:'OrganisationGet' ,params: { id:item.id }}">
                                <b-row no-gutters>
                                    <b-col md="4" class="align-self-center" v-if="$mobileWidth() && item.image !== def_img">
                                        <b-card-img :src="item.image" class="rounded-0"></b-card-img>
                                    </b-col>
                                    <b-col md="4" class="align-self-center" v-else-if="!$mobileWidth()">
                                        <b-card-img :src="item.image" class="rounded-0"></b-card-img>
                                    </b-col>
                                    <b-col md="8" class="favorite_body">
                                        <b-card-body>
                                            
                                            <b-card-title class="favorite_title">{{item.name}}</b-card-title>
                                            <b-card-text class="favorite_text" v-html="cutText(item.description, limit)"></b-card-text>
                                            <b-col cols="12" class="favorite_footer mt-2">
                                                <b-row>
                                                    <b-col cols="12" class="favorite_text" v-for="address in item.addresses.slice(0, 1)" :key="address.address">
                                                        <div v-if="address.address">Адрес: {{address.address}}</div>
                                                        <div v-if="(address.distance_integer > 0 && geo.isActive)">От Вас: {{address.distance}}</div>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-card-body>
                                    </b-col>
                                </b-row>
                            </router-link>
                        </b-card>
                        </div>
                        <div v-else>
                            <b-card>
                                Пока вы не добавили ни одной организации
                            </b-card>
                        </div>
                    </div>
                    <div v-else>
                        <PreLoadBox></PreLoadBox>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
/* eslint-disable no-console */
import PreLoadBox from "../../Preloader/coin";

export default {
    name: "Favorite",
    components: {
        PreLoadBox,
    },
    metaInfo() {
        return {
            title: `Избранное - `,
            meta: [
                {name: 'description', itemprop: 'description', content: `Избранные организации в Окей Город ${this.$myCity.name}.` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Избранное - ${this.$myCity.name}`},
                { property: 'og:description', content: `Избранные организации в Окей Город ${this.$myCity.name}.` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            list: [],
            org: [],
            limit: 120,
            def_img: 'https://city.ooogoroda.mobi/uploads/images/def_Organizations.jpg',

        }
    },
    methods: {
        api() {
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Favorite.getList';
            this.$http.get(url, {
                headers: {
                    ['X-Access-Token']: this.$user.token
                }
            }).then((response) => {
                this.list = response.data.response;
                this.list.forEach(elem => this.apiOrg(elem.item_id));
                this.preLoad = 'finish';
            })
        },
        apiOrg(orgid) {
            let url = this.$config.api_url + this.$route.params.city + '/Organizations.get';
            this.$http.get(url, {
                params: {
                    id: orgid,
                }
            }).then((response) => {
                this.org.push(response.data.response.item);
            })
        },
        cutText(value, limit) {
            return value.length > limit ?
                value.slice(0, limit - 3) + '...' :
                value;
        }
    },
    mounted() {
        this.api();
    },

}
</script>
<style scoped>
@media only screen and (max-width: 991px) {
    .right_menu {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    
}

.favorite_header {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px 0;
    text-transform: uppercase;
    margin-bottom: 15px;

}

.favorite_card {
    margin-bottom: 10px;

}

.favorite_card:hover {
    background: #4a76a8;
    color: #fff;
    transition: .5s;
}

.favorite_card:hover .favorite_title {
    color: #fff;
    transition: .5s;
}

.favorite_card:hover .favorite_footer {
    color: #fff;
    transition: .5s;
}

.favorite_card:hover .favorite_text {
    color: #fff;
    transition: .5s;
}

.favorite_card a {
    text-decoration: none;
    color: #2c3e50;
}

.favorite_title {
    padding-right: 40px;
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    text-decoration: none;

}

.favorite_text {
    padding-right: 40px;
    text-align: left;
    font-size: 12px;

}

.card-body {
    padding: 10px;
}

.favorite_footer {
    padding: 2px 5px 2px 0;
}

.adress_box {
    display: flex;
    line-height: 24px;
}

</style>